import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { pluck, path, prop, map } from 'ramda';
import styled from '@emotion/styled';
import theme from '@greenberry/salal/theme';
import Heading from '@greenberry/salal/components/Heading';
import HeroV4 from '@greenberry/salal/components/Hero/v4';
import Text from '@greenberry/salal/components/Text';
import Section from '@greenberry/salal/components/Section';
import MainWrapper from '@greenberry/salal/components/MainWrapper';
import Accordion from '@greenberry/salal/components/Accordion';
import Button from '@greenberry/salal/components/Button';
import Icon from '@greenberry/salal/components/Icon';
import mq from '@greenberry/salal/utils/mediaQuery';

import sortQuestionsByCategory from '../utils/sortQuestionsByCategory';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

const StyledMainWrapper = styled(MainWrapper)`
  background-color: #fff;
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: ${theme.spacing('xl')};
`;

const MainContent = styled.div`
  width: 100%;
  flex: 1 1 auto;
`;

const CategoryWrapper = styled.div`
  flex: 0 0 auto;
  width: 100%;
  font-size: ${theme.fontSize('mobile.xl')};
  line-height: ${theme.get('type.lineHeight.headings.mobile.xl')};
  font-weight: ${theme.get('type.fontWeight.bold')};
  color: ${theme.color('alphaListNavigation.color')};
  margin-bottom: ${theme.spacing('m')};
  ${mq('3')} {
    text-align: center;
    font-size: ${theme.fontSize('xxl')};
    line-height: ${theme.get('type.lineHeight.headings.xxl')};
    margin-bottom: ${theme.spacing('l')};
  }
`;

const StyledSection = styled(Section)`
  max-width: 800px;
`;

const FaqLinkWrapper = styled.div`
  margin-top: ${theme.spacing('m')};
`;

const Questions = () => {
  const { datoCmsFaq: copy, allDatoCmsQuestion } = useStaticQuery(graphql`
    query getQuestions {
      datoCmsFaq {
        title
        intro
        seoMetaTags {
          tags
        }
      }
      allDatoCmsQuestion(sort: { order: ASC, fields: question }) {
        edges {
          node {
            title: question
            body: answer
            buttonLabel
            buttonLink
            category
          }
        }
      }
    }
  `);

  const faqList = sortQuestionsByCategory(
    pluck('node', allDatoCmsQuestion.edges)
  );

  return (
    <Layout>
      <SEO tags={path(['seoMetaTags', 'tags'], copy)} />
      <HeroV4
        content={
          <>
            <Heading
              component="h1"
              size="xxl"
              weight="semibold"
              appearance="secondary"
            >
              {prop('title', copy)}
            </Heading>
            <Text size="m" appearance="base">
              {prop('intro', copy)}
            </Text>
          </>
        }
        background="transparent"
        align="left"
      />
      <StyledMainWrapper>
        <StyledSection size="1">
          <Wrapper>
            {map(
              category => (
                <Container key={category}>
                  <CategoryWrapper>{category}</CategoryWrapper>

                  <MainContent>
                    {map(
                      question => (
                        <Accordion
                          key={question.title}
                          link={
                            question.buttonLink &&
                            question.buttonLabel && (
                              <FaqLinkWrapper>
                                <a
                                  href={question.buttonLink}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <Button styling="naked" type="button">
                                    <span>{question.buttonLabel}</span>
                                    <Icon size="lg" type="arrowCircleRight" />
                                  </Button>
                                </a>
                              </FaqLinkWrapper>
                            )
                          }
                          {...question}
                        />
                      ),
                      faqList[category]
                    )}
                  </MainContent>
                </Container>
              ),
              Object.keys(faqList).filter(key => faqList[key].length)
            )}
          </Wrapper>
        </StyledSection>
      </StyledMainWrapper>
    </Layout>
  );
};

export default Questions;
